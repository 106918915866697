$back-color: #d3d3d3;
$border-radius: 6px;
$border-color: black;

.BackgroundColored {
  background-color: $back-color;
}

.SampleRowFooter {
  display: flex;
  flex-direction: row;
  align-items: center;

  border-left: 1px solid;
  
  border-bottom: 1px solid;

  border-bottom-color: $border-color;
  border-left-color: $border-color;
  border-right-color: $border-color;

  border-bottom-left-radius: $border-radius;
  

  min-height: 20px;
  background-color: $back-color;
}

.Handle {
  border-left: 1px solid;
  border-right: 1px solid;
  border-top: 1px solid;
  border-top-left-radius: $border-radius;
  border-top-color: $border-color;
  border-left-color: $border-color;

  background-color: $back-color;
}

.RowEnd {
  border-left: 1px solid;
  border-right: 1px solid;
  border-top: 1px solid;
  border-top-right-radius: $border-radius;
  border-bottom-right-radius: $border-radius;
  
  background-color: $back-color;

  border-top-color: $border-color;
  border-right-color: $border-color;
}

.AccordianHeight {
  overflow: hidden;
  transition: max-height 0.6s ease;
  max-height: 0;

  &.Expanded {
    max-height: 1000px;
  }
}

.Accordian {
  background-color: green;
  max-height: 0;
  transition: transform 0.4s ease;
  transform: translateY(-100%);

  &.Expanded {
    transform: translateY(0);
  }
}

.Solo {
  &.active {
    background-color: rgb(246, 95, 92);
  }
  &.hover {
    background-color: rgb(246, 95, 92);
  }
}

.MutedAndDisabled {
  background-color: rgb(55, 132, 151);
}

