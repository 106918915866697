$primary-color: #19857b;

.CinemaWindow {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  overflow: auto;
}

.LayerLane {
  width: 140px;
  padding: 0;
}

.FocusLane {
  width: 160px;
  padding: 0;
  border: 1px solid black;
  border-radius: 5px;
  margin: 2px;
  overflow-x: hidden;
  overflow-y: hidden;
}

.CinemaLayer {
  border: 1px solid black;
  border-radius: 5px;
  margin: 2px;
  margin-bottom: 10px;
  background: gray;
  &.hover {
    border: 1px solid rgb(187, 228, 117);
  }

  &.selected {
    margin: 1px;
    margin-bottom: 9px;
    border: 2px solid $primary-color;
    background-color: $primary-color;
  }
}

.CinemaEmptyLayer {
  border: 1px solid black;
  border-radius: 5px;
  background: gray;
}

.LayerCard {
  display: flex;
  flex-direction: column;
}

.IconButton {
  background: transparent;
  padding: 0;
}

.HeaderStyle {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 10px;
  border-bottom: 1px solid black;
  min-height: 30px;

  &.Selected {
    background-color: $primary-color;
  }
}

.ShaderSmallView {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  padding-left: 10px;
  padding-bottom: 10px;
  width: 100%;

  &.Selected {
    background-color: $primary-color;
  }
}

.ShaderImageEdit {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.Circle {
  width: 60px;
  height: 60px;
  background-color: lightgrey;
  border-radius: 50%;
}

.GizmosRowView {
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  background-color: grey;
  border: 3px solid transparent;
  width: 100%;
  &.enabled {
    border: 3px solid $primary-color;
  }
}

.BindableItem {
  background-color: #808080;
  display: flex;
  //align-items: center;
  flex-direction: column;
  padding-left: 5px;
  padding-bottom: 5px;
  border: 1px solid black;

  &.pinned {
    background-color: $primary-color;
  }
}


.PopupUniformSettings {
  display: flex;
  flex-direction: column;
  padding: 10px;
}
