
.volumeMeter {
  position: relative;
  width: 24px;
  height: 100%;
  overflow: hidden;
  background-color: inherit;
}

.volumeMeterBg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #ddd;
}

.volumeMeterInner {
  background-color: green;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transform-origin: -100% 100%;
  transition: transform 0.05s ease-out;
}

.volumeMeterDivider {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 5%;
  background-color: inherit;
}
