.MainContainer {
  flex-direction: column-reverse;
  display: flex;

  margin-top: 0px;

  margin-bottom: 0px;
  overflow: hidden;
  height: 100%;
}

.topHalfWindow {
  height: 0%;
  flex: 1;
  display: flex;
  margin-top: 10px;
}

.bottomHalfWindow {
  height: 300px;
  min-height: 200px;
}

.topLeftWindow {
  flex: 1;
  display: flex;
}

.audioWindow {
  flex: 1;
  display: flex;
  flex-direction: column-reverse;
  height: 100%;
}

.cinemaWindow {
  flex: 1;
  display: flex;
  flex-direction: column-reverse;
  height: 100%;
}

.topRightWindow {
  width: 45%;
  height: 100%;
  overflow: hidden;
}

// .topLeftWindow_Top {
//   flex: 1 0 0;
//   overflow: auto;
// }

.topLeftWindow_Bottom {
}
