$primary-color: #19857b;
$primary-background: #666;
$shader-preview-width: 300px;

.ShaderMain {
  width: 100%;
  height: 100%;
  display: flex;
  background-color: $primary-background;
}


.FooterView {
  width: 100%;
  height: 100%;
  padding-top: 3px;
  padding-left: 10px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  position: relative;
}

.SearchResultsBox {
  width: 100%;
  height: 100%;
  border: 1px solid black;
  margin-top: 5px;
  display: grid;
  grid-template-columns: auto auto auto;
  overflow: auto;
}

.TabPanel {
  width: 100%;
  height: 100%;
}

.ShaderPeekView {
  width: 200px;
  min-width: 200px;
  height: 200px;
  border: 1px solid black;
  overflow: hidden;

  margin: 2px;
  &.selected {
    margin: 1px;
    margin-bottom: 9px;
    border: 2px solid $primary-color;
    background-color: $primary-color;
  }
}

.ShaderPeekViewHeader {
  display: flex;
  height: 30px;
  width: min-content;
  padding: 0px;
  margin: 0px;
  align-items: center;
}

.ModelPeakView {
  width: 200px;
  border: 1px solid black;
  overflow: hidden;
  background-color: gray;
}
