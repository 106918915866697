

.ValueProgressBar {
  display: flex;
  margin-top: 1px;
  align-items: center;
}

.ValueProgressRender {
    margin: 5;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    border: 1px solid black;
    background-color: gray;
    width: 60px;
		height: 10px;
  }

